export default {
    org: process.env.VUE_APP_ISSUER.split("/oauth2")[0],
    oidc: {
      client_id: process.env.VUE_APP_CLIENT_ID,
      issuer: process.env.VUE_APP_ISSUER,
      redirect_uri: process.env.VUE_APP_REDIRECT_URI,
      scope: process.env.VUE_APP_SCOPES,
      tokenManager: {
        storage: process.env.VUE_APP_SESSION_STORAGE
      }
    },
    loginRedirect: true,
    api: process.env.VUE_APP_API,
    groupsAPI: process.env.VUE_APP_GROUPAPI,
    registerTodayLink: process.env.VUE_APP_ANGELENO_REGISTER_TODAY,
    gSheetAngelenoCards: process.env.VUE_APP_ANGELENO_CARDS_ENDPOINT_GS,
    contactPageAngeleno: process.env.VUE_APP_ANGELENO_CONTACT_FORM,
    //Added for updated Landing Page
    homeAnimalServiceLink:process.env.VUE_APP_ANGELENO_HOME_ANI_LINK,
    homeRAMPLink:process.env.VUE_APP_ANGELENO_HOME_RAMP_LINK,
    homeLAHDLink:process.env.VUE_APP_ANGELENO_HOME_LAHD_LINK,
    
    brand: {
      logo: process.env.VUE_APP_LOGO,
      logo_inv: process.env.VUE_APP_LOGO_INVERSE,
      color: process.env.VUE_APP_MAINCOLOR,
      name: process.env.VUE_APP_COMPANY_NAME,
      recaptcha_key: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
    authTypesMap: {
      "callOKTA": "Phone Call",
      //"emailOKTA": "Email",
      "smsOKTA": "SMS Text",
      //"webauthnFIDO": "FIDO2",
      "token:software:totpGOOGLE": "Authenticator App",
      //"token:software:totpOKTA": "Okta Authentication",
    },
    authImagesMap: {
      "callOKTA": "voicecall_38x38.png", 
      "smsOKTA": "sms_38x38.png",
      "token:software:totpGOOGLE": "hotpFactor_38x38.png"
    },
    mfaGroupID: process.env.VUE_MFA_GROUPID
  };
  