<template>
            <a :href="card.link" target="_blank" class="card shadow bg-lightgrey h-100 text-center d-flex">
                <div class="img-menu-body p-3 h-100">
                    <div class="img-menu-img mt-3">
                       <!-- <img :src="require('../../images/'+ card.image)" >                    -->
                       <img v-bind:src="'data:image/jpeg;base64,'+card.image" />
                    </div>
                    
                    <div class="img-menu-text">
                        <p class="my-3 h5 font-weight-normal ">
                           <!-- {{card.id}} .   {{card.appname}}-->
                           {{card.appname}}
                            </p>
                        <br/>
                        <p class="mx-3 text-description">
                         {{card.description}}
                        </p>

                        <p class="my-3 h7 tagsHidden">
                            {{card.tags}}
                        </p>
                    </div>
                </div>  
            </a>

</template>

<script>


export default {
  name: "ListItems",
  props: ["card"]
};




</script>
<style scoped>


.img-menu-img.mt-3 {
margin-top: 12px!important;
height: 120px;
width: 100%;
background-color: #ffffff;
margin-left: auto;
margin-right: auto;
display: flex;
justify-content: center;
align-items: center;
}

.img-menu-img.mt-3 img {
vertical-align: middle;
max-width: 200px;
max-height: 100px;
width: auto;
height: auto;
}

.tagsHidden
{
    display: none;
}

.mx-3.text-description{
font-size: 1.1rem;
}



</style>