<template>
  <div class="AngelenoLogin">
    <AngelenoLogin/>
  </div>
</template>

<script>
  import AngelenoLogin from '@/components/AngelenoLogin'

  export default {
    name: 'AngelenoLogin',
    components: {
      AngelenoLogin
    }
  }
</script>