<template>
<div class="help-container">
    <h3 class="header-text" style="padding-top: 60px; padding-bottom: 60px;">Contact Us</h3>
    <v-container>
      <iframe :src="this.$config.contactPageAngeleno"
      title="Angeleno Contacts Us"
      width="100%" style="border:none;" height="500px"></iframe>


      

    </v-container>
  </div>
  </template>
<script>
export default {
  name: "Contact_us",
  components: {},

  data() {
    return {
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {},
  },
};
</script>