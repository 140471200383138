<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="content"
      :expanded.sync="expanded"
      :hide-default-footer="true"
      :single-expand="singleExpand"
      item-key="question"
      show-expand
      class="elevation-1 vue-table"
    >
      <template v-slot:top>
      </template>
      <template v-slot:expanded-item="{ headers, item }" >
        <td :colspan="headers.length"><span v-html="item.content"></span></td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "Account_Help",
  components: {},

  data() {
    return {
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: "Questions",
          align: "start",
          sortable: false,
          value: "question",
          class: "help-table-header"
        },
      ],
      content: [
        // { 
        //     question: "What is BETA?", 
        //     content: require("@/views/html/Beta.xhtml") 
        // },
        { 
            question: "How do I register for an Angeleno Account?", 
            content: require("@/views/html/RegisterAngeleno.xhtml") 
        },
        { 
            question: "What do I do if I am not receiving the account activation email from Angeleno?", 
            content: require("@/views/html/EmailSpamInfo.xhtml") 
        }, 

        { 
            question: "How do I sign in to my account?", 
            content: require("@/views/html/SignAccount.xhtml") 
        },
        { 
            question: "How do I change my profile information?", 
            content: require("@/views/html/ChangeAccountInfo.xhtml") 
        },
      ],
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      
    },
  },
};
</script>
<style>
.v-data-table__mobile-table-row{
  display: table-row !important;
}
</style>