<template>
  <div style="margin-top: 36px;">
    <div class="relative text-gray-900 text-center">
      <input
        type="text"
        v-model="search"
        placeholder="Search for a service"
        
        class="pl-3 py-6 form-search form-control text-center">
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit("input", updated);
      }
    }
  }
};

</script>