// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../assets/images/forgot_pass.png");
var ___HTML_LOADER_IMPORT_1___ = require("../../assets/images/reset_pass_sms.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../assets/images/reset_pass_voice_call.png");
var ___HTML_LOADER_IMPORT_3___ = require("../../assets/images/password_reset.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"faq-container\"> <p>If you forgot your password and want to reset it then you can go to <a href=\"https://angelenologin.lacity.org/signin/forgot-password\">https://angelenologin.lacity.org/signin/forgot-password</a> and enter the email address you used when registering for an account. Then you can choose one of the three options shown to reset your password (Note: Reset via Text Message and Reset via Voice Call can only be used if you already have a mobile phone number registered to your account.) </p> <div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"center\"/> </div> <p>(Reset via Text Message) If you choose this option then you will receive a Text Message message containing a code that you can enter to verify that you own the account. Enter this code into the box to reset your password. </p> <div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" class=\"center\"/> </div> <p>(Reset via Voice Call) If you choose this option then you will receive a phone call which will give you a code that you can enter to verify that you own the account. Enter this code into the box to reset your password. </p> <div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" class=\"center\"/> </div> <p>(Reset via Email) If you choose this option then you will receive an email with a link to reset your password inside. Click on the link to reset your password. </p> <div> <img src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" class=\"center\"/> </div> </div>";
// Exports
module.exports = code;