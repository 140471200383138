<style>
.errorMsg {
  color: red;
}
</style>
<template>
  <v-row align="center"
          justify="center" >
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>
        <span class="headline">Authenticator App Authentication</span>
      </v-card-title>
      <v-container v-if="start">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">Enter code displayed from the application</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="code" label="code" required v-on:keyup.enter="verifyCode(code)"></v-text-field>
              </v-col>
            </v-row>           
            <p class="errorMsg" v-if="errors.messageAlert">{{ errors.messageAlert }}</p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="verifyCode(code)">Verify</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      loader: false,
      start: true,
      code: "",
      alertData: {
        typeAlert: "",
        messageAlert: ""
      },
      errors: {
        messageAlert: ""
      }
    };
  },
  components: {},
  props: {
    factorType: String,
    factorTitle: String,
    factor: undefined,
    stateToken: undefined,
    acctVerification: String
  },
  created() {},
  watch: {
    verify: function(val) {
      if (val) {
        window.setTimeout(() => {
          this.resend = true;
        }, 30000);
      }
    }
  },
  methods: {
    async verifyCode(passcode) {
      const url = `${this.$config.api}/api/v1/authn/factors/${this.factor.id}/verify`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        stateToken: this.stateToken,
        passCode: passcode
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          if (res.data.status == "SUCCESS") {
            this.errors.messageAlert = "";
            this.loader = false;
            this.$session.set("acctVerification", this.acctVerification);
            this.$router.push({
              name: "settings",
              params: {
                focusTab: 0
              }
            });
          } else if (res.data.status == "MFA_CHALLENGE") {
            this.errors.messageAlert = "Code must not be empty";
            this.loader = false;
          }
        }
      } catch (err) {
        try {
          this.alertData.messageAlert = err.response.data.errorSummary;
          this.errors.messageAlert = err.response.data.errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.alertData.messageAlert = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    }
  }
};
</script>