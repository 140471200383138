<template>
  <div class="home">
    <Home/>
  </div>
</template>

<script>
  import Home from '@/components/Angeleno'

  export default {
    name: 'home',
    components: {
      Home
    }
  }
</script>