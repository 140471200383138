<style>
.errorMsg {
  color: red;
}.contents{
  display: contents;
} 
</style>
  <template>
  <v-container>
    <v-container v-if="start ">
      <v-row align="center"
          justify="center">
       <v-col  cols="12" sm="6">
        <v-form  style="contents" ref="form" v-model="valid" @submit="verifyAccount" onSubmit="return false;">
          <v-row>Please verify your password</v-row>
          <v-row><v-text-field
            v-model="password"
            label="Password"
            :rules="passwordRules"
            required
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field></v-row>
          <v-row> <p class="errorMsg" v-if="errors.messageAlert">{{ errors.messageAlert }}</p></v-row>
          <v-row justify="end">
            <v-btn
              small
              outlined
              @click="verifyAccount"
              :disabled="!valid"
              :color="valid ? 'blue' : null"
            >Verify</v-btn>
          </v-row>
        </v-form>
    </v-col>
     </v-row>
    </v-container>
    <v-container fluid v-if="verifyFactor"><VerifyFactor v-bind:authmethods="factors" 
    v-bind:stateToken="this.stateToken" 
    v-bind:acctVerification="this.acctVerification" 
    @ShowAlert="onAlert"/></v-container>    
 </v-container>
</template>
<script>
import axios from "axios";
import VerifyFactor from "./VerifyFactor";
export default {
  name: "AcctVerification",
  components: {
    VerifyFactor
  },
  data() {
    return {
      valid: true,
      password: undefined,
      showPassword: false,
      passwordRules: [v => !!v || "Password is required"],
      error: false,
      errors: {
        messageAlert: ""
      },
      factors: undefined,
      acctVerification: undefined,
      stateToken: undefined,
      start: true,
      verifyFactor: false
    };
  },
  created() {},
  methods: {
    async verifyAccount() {
      const url = `${this.$config.api}/api/authn/v1/authn`;
      const accessToken = await this.$auth.getAccessToken();
      try {
        const payload = {
          username: this.$root.$children[0].userinfo.preferred_username,
          password: this.password,
          options: {
            multiOptionalFactorEnroll: false,
            warnBeforePasswordExpired: false
          }
        };
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          this.acctVerification = res.data.acctVerification;
          this.stateToken = res.data.stateToken;
          switch (res.data.status) {
            case "SUCCESS":
              this.$session.set("acctVerification", this.acctVerification);
              this.$router.push({
                name: "settings",
                params: {
                  focusTab: 0
                }
              });
              break;
            case "MFA_REQUIRED":
              this.start = false;
              this.verifyFactor = true;
              this.factors = res.data._embedded.factors;
              this.$router.push({
                name: "settings",
                params: {
                  focusTab: 0
                }
              });
              break;
            case "LOCKED_OUT":
              this.errors.messageAlert =
                "Account locked for too many invalid attempts.";
              break;
            case "PASSWORD_EXPIRED":
              this.errors.messageAlert = "Password expired.";
              break;
            case "MFA_ENROLL":
              this.errors.messageAlert =
                "MFA_ENROLL the users should enroll before getting here";
              break;
            default:
              break;
          }
        } else {
          this.errors.messageAlert = "Password: Sign in failed!";
        }
      } catch (err) {
        this.errors.messageAlert = "Password: Sign in failed!";
        console.log(err);
      }
      return false;
    },
    onAlert: function(obj) {
      this.$emit("ShowAlert", obj);
    }
  }
};
</script>