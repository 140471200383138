<style>
.errorMsg {
  color: red;
}
</style>
<template>
  <div>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">Add</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Enroll in multifactor authentication</span>
        </v-card-title>
        <v-container v-if="start">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  Set up multifactor authentication.
                  Continue multifactor authentication setup to add an additional layer of security when signing in to your account
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="addFactor()">Next</v-btn>
          </v-card-actions>
        </v-container>
        <v-container v-if="second">
          <v-card-text>
            <v-container>
              <v-row>
                Enter SMS enabled phone to recived verification code
                <v-col cols="12">
                  <countryCode :onSelected="countryCodeSelected" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-text-field v-model="countryCodeNum" required readonly></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field v-model="phone" label="Phone Number" required></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="addSMSFactor()">Next</v-btn>
          </v-card-actions>
        </v-container>
        <v-container v-if="verify">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">Enter code displayed sent to your phone via SMS</v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="code" label="code" required></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="resend">
                <v-col cols="3">
                  <v-btn color="blue darken-1" text @click="resendCode(factID)">Resend</v-btn>
                </v-col>
              </v-row>
              <p class="" v-if="resent">Code resent successfully</p>
              <p class="errorMsg" v-if="errors.messageAlert">{{ errors.messageAlert }}</p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="verifyCode(factID,code)">Verify</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import countryCode from "./CountryCode";

export default {
  data() {
    return {
      appUserInfo: this.$root.$children[0].userinfo,
      dialog: false,
      loader: false,
      activation: [],
      start: true,
      second: false,
      verify: false,
      resend: false,
      resent: false,
      code: "",
      phone: "",
      countryCodeNum: "+1",      
      alertData: {
        typeAlert: "",
        messageAlert: ""
      },
      factID: "",
      errors: {
        messageAlert: ""
      }
    };
  },
  components: {
    countryCode
  },
  props: {
    factorID: String,
    factorType: String,
    factorTitle: String,
    authmethods: undefined
  },
  created() {
    if(this.appUserInfo.MobilePhone)
    {
      this.phone = this.appUserInfo.MobilePhone;
    }
  },
  watch: {
    verify: function(val) {
      if (val) {
        window.setTimeout(() => {
          this.resend = true;
        }, 30000);
      }
    }
  },
  methods: {
    countryCodeSelected: function(value) {
      this.countryCodeNum = value.value;
    },
    addFactor() {
      this.start = false;
      this.second = true;
    },
    async addSMSFactor() {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors?updatePhone=true`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      //TODO: need to validate phone number
      const payload = {
        factorType: "sms",
        provider: "OKTA",
        profile: {
          phoneNumber: this.countryCodeNum + "-" + this.phone
        }
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        this.second = false;
        if (res.status == 200) {
          this.factID = res.data.id;
          if (res.data.status == "ACTIVE") {
            this.dialog = false;
            this.overlayMessage = "SMS Activated";
            this.saved = true;
            this.verify = false;
            this.errors.messageAlert = "";
            this.alertData.messageAlert = this.factorTitle + " added";
            this.alertData.typeAlert = "Success";
            this.$emit("ShowAlert", this.alertData);
            this.$emit("AddFactorGroup");
            let index = this.authmethods.find(
              x => x.factorType == this.factorType
            );
            index.status = res.data.status;
            index.id = res.data.id;
          } else {
            this.verify = true;
          }
          //this.$data.authmethods = res.data;
          // res.data is the information that I need to display
        }
      } catch (err) {
        this.second = false;
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
      }
      this.loader = false;
    },
    async verifyCode(factorId, passcode) {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors/${factorId}/lifecycle/activate`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        passCode: passcode
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          this.errors.messageAlert = "";
          this.alertData.messageAlert = this.factorTitle + " added";
          this.alertData.typeAlert = "Success";
          this.$emit("ShowAlert", this.alertData);
          this.$emit("AddFactorGroup");
          let index = this.authmethods.find(
            x => x.factorType == this.factorType
          );
          index.status = res.data.status;
          index.id = res.data.id;
          this.loader = false;
          this.dialog = false;
        }
      } catch (err) {
        try {
          this.alertData.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.errors.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    async resendCode(factorId) {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors/${factorId}/resend`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        factorType: "sms",
        provider: "OKTA",
        profile: {
          phoneNumber: this.countryCodeNum + "-" + this.phone,
          phoneExtension: this.phoneExtension
        }
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
            this.resent = true;
            window.setTimeout(() => {
          this.resent = false;
        }, 5000);
        }
      } catch (err) {
        try {
          this.alertData.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.errors.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
      }
    }
  }
};
</script>