<template>
  <div>
    <v-alert :value="success" type="success" transition="scale-transition">{{message}}</v-alert>

    <v-alert :value="info" type="info" transition="scale-transition">{{message}}</v-alert>

    <v-alert :value="warning" type="warning" transition="scale-transition">{{message}}</v-alert>

    <v-alert :value="error" type="error" transition="scale-transition">{{message}}</v-alert>
  </div>
</template>
<script>
export default {
  data() {
    return {
      success: false,
      warning: false,
      info: false,
      error: false
    };
  },
  props: {
    alertType: String,
    message: String
  },
  methods: {
  },
  watch: {
    alertType: function(val) {
      if (val!='') {
        switch (val) {
          case "Success":
            this.success = true;
            window.setTimeout(() => {
              this.success = false;
              this.$emit('clear-Alert');
            }, 5000);
            break;
          case "Info":
            this.info = true;
            window.setTimeout(() => {
              this.info = false;
              this.$emit('clear-Alert');
            }, 5000);
            break;
          case "Warning":
            this.warning = true;
            window.setTimeout(() => {
              this.warning = false;
              this.$emit('clear-Alert');
            }, 5000);
            break;
          case "Error":
            this.error = true;
            window.setTimeout(() => {
              this.error = false;
              this.$emit('clear-Alert');
            }, 5000);
            break;
        }          
      }
    }
  },
  computed: {    
  }
};
</script>