<template>
<div v-if="$route.path != '/AngelenoAppLogin/0oad5oflgkrVq9uC4x6'">
    <v-menu
        offset-y offset-overflow
        open-on-hover
        transition="scale-transition" 
        >
        <template v-slot:activator="{ on }">
        <v-btn class="mx-2 btn-title-bar nav-main"
             outlined
            v-on="on"
        >
            <v-icon left dark>mdi-account</v-icon>
            {{accountName}}
        </v-btn>
        </template>
        <v-card v-if="userinfo">
            <v-list>
                <v-list-item>
                <v-list-item-avatar 
                    v-if="userinfo.profile"
                    >
                    <img :src="userinfo.profile" alt="profile-pic">
                </v-list-item-avatar>
                <v-list-item-avatar
                    v-if="!userinfo.profile"
                    color="indigo" size="48"
                    >
                    <span class="white--text headline">{{initials}}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{userinfo.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{userinfo.preferred_username}}</v-list-item-subtitle>
                </v-list-item-content>

                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item>
                <v-btn text light small
                    @click="showProfile"
                    >
                    View/Edit Profile
                </v-btn>
                </v-list-item>
                <v-list-item>
                <v-btn text light small
                    @click="changePassword"
                    >
                    Change Password
                </v-btn>
                </v-list-item>
            </v-list>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="logout">Logout</v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</div>
</template>

<script>
export default {
    name: 'profile-button',
    data () {
        
        return {token: '',
        origin:''}
    },
    props: {
        userinfo: Object
    },
    created: function(){
        this.origin = window.location.origin;
      },
    computed: {
        accountName() {
            return this.userinfo ? this.userinfo.given_name : ''
        },
        initials() {
            if (!this.userinfo || !this.userinfo.given_name)
                return ''
            return this.userinfo.given_name.substring(0,1) + this.userinfo.family_name.substring(0,1)
        }
    },
    methods: {
        async logout () {
            this.token = await this.$auth.getIdToken();
            await this.$auth.logout()
            window.location.href=process.env.VUE_APP_ISSUER +"/v1/logout?id_token_hint="+this.token+"&post_logout_redirect_uri="+this.origin;
        },
        showProfile() {
            this.$router.push({
                name: 'settings',
                params: {
                    focusTab: 0
                }
            })
        },
        changePassword() {
            this.$router.push({
                name: 'settings',
                params: {
                    focusTab: 1
                }
            })
        }
    }    
}
</script>
<style>
.v-toolbar__content,
.v-toolbar__extension {
  background-color: #03a751;
  color: white !important;
  font-family: "Oswald" !important;
  font-weight: 500 !important;
}
.btn-title-bar {
  color: white !important;
  font-weight: 500 !important;
}
@media screen and (max-width: 815px) {
  .nav-main{
    display: none !important;
  }

}
</style>