<template>
    <div id="AngelenoLogin"></div>     
</template>

<script>

export default { 
    name:"AngelenoLogin",
    async created() {
        const issuer = this.$config.oidc.issuer
        this.org = issuer.split('/oauth2')[0]
    },
}
</script>