<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="content"
      :expanded.sync="expanded"
      :hide-default-footer="true"
      item-key="question"
      show-expand
      class="elevation-1"
      @click:row="handleClick"
    >
      <template v-slot:top>
      </template>
      <template v-slot:expanded-item="{ headers, item }" >
        <td :colspan="headers.length"><span v-html="item.content"></span></td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "Password_Help",
  components: {},

  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: "Questions",
          align: "start",
          sortable: false,
          value: "question",
          class: "help-table-header"
        },
      ],
      content: [
        { 
            question: "How do I reset my password?", 
            content: require("@/views/html/PasswordReset.xhtml") 
        },
      ],
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {

    },
    handleClick(e){
      console.log(e);
    }
  },
};
</script>