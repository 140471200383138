<template>
  <v-row>
    <AddTOTP
      v-if="factorType == 'token:software:totpGOOGLE'"
      @AddFactorGroup="onFactorGroup"
      @ShowAlert="onAlert"
      v-bind:authmethods="authmethods"
      v-bind:factorType="this.factorType"
      v-bind:factorTitle="this.factorTitle"
    />
    <AddSMS
      v-if="factorType == 'smsOKTA'"
      @AddFactorGroup="onFactorGroup"
      @ShowAlert="onAlert"
      v-bind:authmethods="authmethods"
      v-bind:factorType="this.factorType"
      v-bind:factorTitle="this.factorTitle"
    />
    <AddVoice
      v-if="factorType == 'callOKTA'"
      @AddFactorGroup="onFactorGroup"
      @ShowAlert="onAlert"
      v-bind:authmethods="authmethods"
      v-bind:factorType="this.factorType"
      v-bind:factorTitle="this.factorTitle"
    />
    <AddWebAuthn
      v-if="factorType == 'webauthnFIDO'"
      @AddFactorGroup="onFactorGroup"
      @ShowAlert="onAlert"
      v-bind:authmethods="authmethods"
      v-bind:factorType="this.factorType"
      v-bind:factorTitle="this.factorTitle"
    />
  </v-row>
  
</template>

<script>
import AddTOTP from "./AddTOTP";
import AddSMS from "./AddSMS";
import AddVoice from "./AddVoice";
import AddWebAuthn from "./AddWebAuthn";

export default {
  name: "profile",
  components: {
    AddTOTP,
    AddSMS,
    AddVoice,
    AddWebAuthn
  },
  data: () => ({    
  }),
  props: {
    factorID: String,
    factorType: String,
    factorTitle: String,
    authmethods: undefined
  },
  methods: {
    onAlert: function(obj) {
      this.$emit("ShowAlert", obj);
    },
    onFactorGroup: function(){
      this.$emit("AddFactorGroup");
    }
  }
};
</script>