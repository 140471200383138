<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed color="error" v-bind="attrs" v-on="on">Remove</v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">Remove factor {{factorTitle}}</v-card-title>
        <v-card-text>Do you confirm to remove {{factorTitle}}? This action is irreversible. If you want to use this factor again you will need to enroll the factor again.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="removeFactor(factorID,factorTitle)">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      alertData: {
        typeAlert: "",
        messageAlert: ""
      },
      dialog: false
    };
  },
  props: {
    factorID: String,
    factorType: String,
    factorTitle: String,
    authmethods: undefined
  },
  methods: {
    async removeFactorGroup() {
      const url = `${this.$config.api}/api/mfa/v1/group`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      try {
        const res = await axios.delete(url, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 204) {
          console.log("success");
        } else {
          console.log("call failed");
        }
      } catch (err) {
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
          console.log(`Something failed here ${err}`);
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    async removeFactor(id, factorTitle) {
      this.dialog = false;
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors/${id}`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;

      try {
        const res = await axios.delete(url, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 204) {
          this.alertData.messageAlert = factorTitle + " removed";
          this.alertData.typeAlert = "Success";
          this.$emit("ShowAlert", this.alertData);
          this.saved = true;
          let index = this.authmethods.find(
            x => x.factorType == this.factorType
          );
          index.status = "NOT_SETUP";
          index.id = undefined;
          this.loader = false;
          await this.removeFactorGroup();
        }
      } catch (err) {
        try {
          this.alert.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.alert.typeAlert = "Error";
          this.$emit("ShowAlert", alert);
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    }
  }
};
</script>