<style>
.errorMsg {
  color: red;
}
</style>
<template>
  <div>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">Add</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Enroll in multifactor authentication</span>
        </v-card-title>
        <v-container v-if="start">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  Set up multifactor authentication.
                  Continue multifactor authentication setup to add an additional layer of security when signing in to your account
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="enrollFactor()">Next</v-btn>
          </v-card-actions>
        </v-container>
        <v-container v-if="second">
          <v-card-text>
            <v-container></v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="addSMSFactor()">Next</v-btn>
          </v-card-actions>
        </v-container>
        <v-container v-if="verify">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">Enter code displayed sent to your phone via SMS</v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="code" label="code" required></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="resend">
                <v-col cols="3">
                  <v-btn color="blue darken-1" text @click="resendCode(factID)">Resend</v-btn>
                </v-col>
              </v-row>
              <p class v-if="resent">Code resent successfully</p>
              <p class="errorMsg" v-if="errors.messageAlert">{{ errors.messageAlert }}</p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="verifyCode(factID,code)">Verify</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      appUserInfo: this.$root.$children[0].userinfo,
      //userId: this.appUserInfo.sub,
      embedded: [],
      attestation: "",
      challenge: "",
      clientData: "",
      dialog: false,
      loader: false,
      activation: [],
      start: true,
      second: false,
      verify: false,
      alertData: {
        typeAlert: "",
        messageAlert: ""
      },
      factID: "",
      errors: {
        messageAlert: ""
      }
    };
  },
  props: {
    factorID: String,
    factorType: String,
    factorTitle: String,
    authmethods: undefined
  },
  async created() {},
  watch: {},
  methods: {
    async enrollFactor() {
      this.start = false;
      this.second = true;
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        factorType: "webauthn",
        provider: "FIDO"
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        this.second = false;
        if (res.status == 200) {
          this.factID = res.data.id;
          this.embedded = res.data._embedded;
          res.data._embedded.activation.challenge = this.strToBin(
            res.data._embedded.activation.challenge
          );
          res.data._embedded.activation.user.id = this.strToBin(
            res.data._embedded.activation.user.id
          ); 
          var that =this;
          res.data._embedded.activation.u2fParams.appid ="http://localhost:8080";
          navigator.credentials
            .create({
              publicKey: res.data._embedded.activation
            })
            .then(function(newCredential) {
              let attestation =  that.binToStr(newCredential.response.attestationObject);
              let clientData = that.binToStr(newCredential.response.clientDataJSON);
              that.verifyCode(that.factID,attestation,clientData);
            })
            .fail(function(error) {
              console.log(error);
            });
        }
      } catch (err) {
        this.second = false;
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
      }
      this.loader = false;
    },
    async verifyCode(factorId, attestation, clientData) {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors/${factorId}/lifecycle/activate`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        attestation: attestation,
        clientData:clientData
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          this.errors.messageAlert = "";
          this.alertData.messageAlert = this.factorTitle + " added";
          this.alertData.typeAlert = "Success";
          this.$emit("ShowAlert", this.alertData);
          this.$emit("AddFactorGroup");
          let index = this.authmethods.find(
            x => x.factorType == this.factorType
          );
          index.status = res.data.status;
          index.id = res.data.id;
          this.loader = false;
          this.dialog = false;
        }
      } catch (err) {
        try {
          this.alertData.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.errors.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    getStringHash(str) {
      var hash = 5381,
        i = str.length;
      while (i) {
        hash = (hash * 33) ^ str.charCodeAt(--i);
      }
      return hash >>> 0;
    },
    base64UrlSafeToBase64(str) {
      return str
        .replace(new RegExp("_", "g"), "/")
        .replace(new RegExp("-", "g"), "+");
    },
    binToStr(bin) {
      return btoa(
        new Uint8Array(bin).reduce(
          (s, byte) => s + String.fromCharCode(byte),
          ""
        )
      );
    },
    strToBin(str) {
      return Uint8Array.from(atob(this.base64UrlSafeToBase64(str)), c =>
        c.charCodeAt(0)
      );
    }
  }
};
</script>