

<template>
<!-- This is actually the Dashboard Tab -->


  <div class="apps">

 <h2 style="margin-top:16px;"></h2>
 <Search v-model="search"/>

  <h2 style="margin-top:16px;">Services</h2>
    <div class="row" v-if="filteredData.length">

         <br/>
          <div class="col-12 my-3 col-sm-6 col-lg-4" v-for="item in filteredData" :key="item.id">
            <ListItem :card="item" this.pazLoad="true"/>
        </div>
     </div>
    <v-row justify="center" v-else-if="!filteredData.length && search === ''">
          <pulse-loader  :loading="loading" :color="color" :size="size" :margin="margin" ></pulse-loader>
     </v-row>


      <br/>
      


      
  <!--<h2>Popular Services Coming Soon</h2>
    <div class="row"  
          v-if="filteredData2.length">

         <br/>
       
        <div class="col-12 my-3 col-sm-6 col-lg-4" v-for="item in filteredData2 " :key="item.id" >
          <ListItem :card="item"/>   
        </div>
   
     </div>

     <v-row justify="center" v-else-if="!filteredData.length && search === ''">  
        <pulse-loader  :loading="loading" :color="color" :size="size" :margin="margin"></pulse-loader>
     </v-row>
     -->


   
</div>
</template>

<script>
  import ListItem from '@/components/AngelenoCard'
  import Search from '@/components/SearchApps'
  import axios from "axios";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'



  

  export default {
    name: 'apps',
    components: {

        ListItem,
        Search,
        PulseLoader
    },
     data: () => {
    return {
      search: "",
      allcards:[],
      allItems:[],
      loadingConnected: true,
      size: '36px',
      margin: '69px',
      color:'#009F31',
      loading: true,
    }
  },
  mounted () {
    var gsheet_url = this.$config.gSheetAngelenoCards;
        axios
        .get(gsheet_url)
        .then(response => (
          
          this.allcards = parseData(response.data, this.$route.params.caauth, this.$route.params.address )  
        )),
        this.allItems = this.filteredData;
        //this.allItems;
    },
     computed: {

    filteredData() {
      const LowerCaseSearch = this.search.toLowerCase();
 
        var flt = this.allcards.filter(
              a => a.connected == 1);

      flt = flt.filter(
        card =>
        card.appname.toLowerCase().includes(LowerCaseSearch) || card.tags.toLowerCase().includes(LowerCaseSearch) || card.description.toLowerCase().includes(LowerCaseSearch)
      ).sort((a, b) => (a.appname) - (b.appname)
      );


      return flt;
    },
      filteredData2() {
      const LowerCaseSearch = this.search.toLowerCase();

       var flt = this.allcards.filter(
              a => a.connected == 0);


      flt = flt.filter(
        card =>
        card.appname.toLowerCase().includes(LowerCaseSearch) || card.tags.toLowerCase().includes(LowerCaseSearch) || card.description.toLowerCase().includes(LowerCaseSearch)
      );
  
      return flt;
    }
  }

  }
  









function parseData(entries, caauth, addr) {
var cardApps = []
var entry;
  entries.forEach(function(value) {
        
        if(value['app_name'] === "NavigateLA" ) {

        if(caauth) {

           entry = {
            "id": value['id'],
            "appname": value['app_name'],
            "description": value['description'],
            "image": value['image_filename'],
            "link": value['link']+"?search="+addr,
            "tags": value['tags'],
            "connected":value['connected']};

          }
          else{
            entry = {
            "id": value['id'],
            "appname": value['app_name'],
            "description": value['description'],
            "image": value['image_filename'],
            "link": value['link'],
            "tags": value['tags'],
            "connected":value['connected']};
          }
        }
        else
        {
          entry = {
            "id": value['id'],
            "appname": value['app_name'],
            "description": value['description'],
            "image": value['image_filename'],
            "link": value['link'],
            "tags": value['tags'],
            "connected":value['connected']};
        }
        cardApps.push(entry);
  });
  return cardApps;
}

</script>


