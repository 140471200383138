<template>
  <v-container fluid>
    <v-row align="center"
          justify="center" >
      <v-col class="d-flex" cols="12" sm="6">
        <v-select
          v-model="this.authmethods[0]"
          :items="authmethods"
          label="Multi-factor Authentication Type"
          @change="changedValue"
          item-text="name"
          item-value="factorType"
        >
          <template v-slot:item="{ item }">
            <img :src="item.image" />
            {{ item.name }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row align="center"
          justify="center">
      <v-col class="d-flex" cols="12" sm="6">
        <v-row align="center"
          justify="center" >
        <VerifyTOTP
          v-if="factorType == 'token:software:totpGOOGLE'"
          @AddFactorGroup="onFactorGroup"
          @ShowAlert="onAlert"
          v-bind:factor="this.authmethods.find(
                authMethod =>
                  authMethod.factorType == 'token:software:totpGOOGLE')"
          v-bind:factorType="this.factorType"
          v-bind:factorTitle="this.factorTitle"
          v-bind:stateToken="this.stateToken"
          v-bind:acctVerification="this.acctVerification"
        />
        <VerifySMS
          v-if="factorType == 'smsOKTA'"
          @AddFactorGroup="onFactorGroup"
          @ShowAlert="onAlert"
          v-bind:factor="this.authmethods.find(
                authMethod =>
                  authMethod.factorType == 'smsOKTA')"
          v-bind:factorType="this.factorType"
          v-bind:factorTitle="this.factorTitle"
          v-bind:stateToken="this.stateToken"
          v-bind:acctVerification="this.acctVerification"
        />
        <VerifyVoice
          v-if="factorType == 'callOKTA'"
          @AddFactorGroup="onFactorGroup"
          @ShowAlert="onAlert"
          v-bind:factor="this.authmethods.find(
                authMethod =>
                  authMethod.factorType == 'callOKTA')"
          v-bind:factorType="this.factorType"
          v-bind:factorTitle="this.factorTitle"
          v-bind:stateToken="this.stateToken"
          v-bind:acctVerification="this.acctVerification"
        />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VerifyTOTP from "./VerifyTOTP";
import VerifySMS from "./VerifySMS";
import VerifyVoice from "./VerifyVoice";
import config from "@/.config.js";

export default {
  name: "VerifyFactor",
  components: {
    VerifyTOTP,
    VerifySMS,
    VerifyVoice
  },
  data: () => ({}),
  props: {
    factorID: String,
    factorType: String,
    factorTitle: String,
    authmethods: undefined,
    stateToken:undefined,
    acctVerification: String
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      for (let authType of this.authmethods) {
        let tempAuthObj =
          config.authTypesMap[
            authType.factorType.concat("", authType.provider)
          ];
        let tempImgObj =
          config.authImagesMap[
            authType.factorType.concat("", authType.provider)
          ];
        authType.name = tempAuthObj;
        authType.image = require("@/assets/img/" + tempImgObj);
        authType.factorType = authType.factorType.concat("", authType.provider);
      }
      this.factorType = this.authmethods[0].factorType;
    },
    onAlert: function(obj) {
      this.$emit("ShowAlert", obj);
    },
    onFactorGroup: function() {
      this.$emit("AddFactorGroup");
    },
    changedValue: function(value) {
      this.factorType = value;
    }
  }
};
</script>