<template>
<!-- This is actually the Dashboard Tab -->


  <div class="home">
    
    <Chicklets/>
<!--
    <ConnectedAppsTemp/>
    <v-divider></v-divider>
    <CityResources></CityResources>
-->

   
</div>
</template>

<script>
  import Chicklets from '@/components/Chicklets'
  //import CityResources from '@/components/CityResources'
  //import ConnectedAppsTemp from '@/components/ConnectedAppsTemp'
//import Chicklets from '../components/Chicklets.vue'

  export default {
    name: 'home',
    components: {
     
        //CityResources,
        //ConnectedAppsTemp,
        Chicklets
    }
  }

/*
  export default {
    name: 'home',
    components: {
      Chicklets,
        CityResources,
        ConnectedAppsTemp
    }
  }
*/

</script>

 