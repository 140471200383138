<template>
  <v-container fluid>
    <v-row></v-row>
    <v-tabs :vertical="verticaltabs" show-arrows v-model="activeTab">
      <v-tab key="profile">Profile</v-tab>
      <v-tab key="Password">Password</v-tab>
      <v-tab key="2FA">Advanced Security</v-tab>
      <v-tab-item key="profile">
        <Profile></Profile>
      </v-tab-item>
      <v-tab-item key="Password">
        <ChangePassword></ChangePassword>
      </v-tab-item>
      <v-tab-item key="2FA">
        <Tfa @ShowAlert="onAlert"></Tfa>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import Profile from "@/components/Profile";
import ChangePassword from "@/components/ChangePassword";
import Tfa from "@/components/Tfa";

export default {
  name: "settings",
  components: {
    Profile,
    ChangePassword,
    Tfa
  },
  data() {
    return {
      activeTab: this.focusTab,
      verticaltabs: true
    };
  },
  props: {
    focusTab: Number
  },
  methods: {
    onAlert: function(obj) {
      this.$emit("ShowAlert", obj);
    },
     onResize() {
    if (window.innerWidth > 1024) {
      this.verticaltabs = true
    } else {
      this.verticaltabs = false
    }
  }
},
created() {
  this.onResize();
  window.addEventListener('resize', this.onResize)
},

beforeDestroy() {
  window.removeEventListener('resize', this.onResize)
},
};
</script>