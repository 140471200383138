<style>
.errorMsg {
  color: red;
}
</style>
<template>
  <v-row align="center"
          justify="center" >
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    
    <v-card align="center"
          justify="center">
      <v-card-title>
        <span class="headline">Phone Call Authentication</span>
      </v-card-title>
      <v-container v-if="start">
        <v-card-text>
          <v-container>
            <v-row>Click Call to call {{factor.profile.phoneNumber}}</v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="verifyVoiceFactor()">Call</v-btn>
        </v-card-actions>
      </v-container>
      <v-container v-if="verify">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">Enter code sent to your phone via phone call</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="code" label="code" required v-on:keyup.enter="verifyCode(code)"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="resend">
              <v-col cols="3">
                <v-btn color="blue darken-1" text @click="resendCode()">Recall</v-btn>
              </v-col>
            </v-row>
            <p class v-if="resent">Phone number recalled successfully</p>
            <p class="errorMsg" v-if="errors.messageAlert">{{ errors.messageAlert }}</p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="verifyCode(code)">Verify</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      loader: false,
      start: true,
      second: false,
      verify: false,
      resend: false,
      resent: false,
      code: "",
      alertData: {
        typeAlert: "",
        messageAlert: ""
      },
      errors: {
        messageAlert: ""
      }
    };
  },
  components: {},
  props: {
    factorType: String,
    factorTitle: String,
    factor: undefined,
    stateToken: undefined,
    acctVerification: String
  },
  created() {},
  watch: {
    verify: function(val) {
      if (val) {
        window.setTimeout(() => {
          this.resend = true;
        }, 30000);
      }
    }
  },
  methods: {
    async verifyVoiceFactor() {
      const url = `${this.$config.api}/api/v1/authn/factors/${this.factor.id}/verify`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        stateToken: this.stateToken
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        this.second = false;
        if (res.status == 200) {
          this.factID = res.data.id;
          if (res.data.status == "MFA_CHALLENGE") {
            this.start = false;
            this.saved = true;
            this.verify = true;
            this.errors.messageAlert = "";
            this.alertData.messageAlert = "Calling now";
            this.alertData.typeAlert = "Success";
          }
        }
      } catch (err) {
        this.second = false;
        try {
          this.alertData.messageAlert = err.response.data.errorSummary;
          this.errors.messageAlert = err.response.data.errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.alertData.messageAlert = "invalid request";
        }
        this.error = true;
        this.saved = true;
      }
      this.loader = false;
    },
    async verifyCode(passcode) {
      const url = `${this.$config.api}/api/v1/authn/factors/${this.factor.id}/verify`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        stateToken: this.stateToken,
        passCode: passcode
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          if (res.data.status == "SUCCESS") {
            this.errors.messageAlert = "";
            this.loader = false;
            this.$session.set("acctVerification", this.acctVerification);
            this.$router.push({
              name: "settings",
              params: {
                focusTab: 0
              }
            });
          } else if (res.data.status == "MFA_CHALLENGE") {
            this.errors.messageAlert = "";
            this.loader = false;
            this.alertData.messageAlert = "Text Message Resent";
            this.alertData.typeAlert = "Success";
            this.$emit("ShowAlert", this.alertData);
          }
        }
      } catch (err) {
        try {
          this.alertData.messageAlert = err.response.data.errorSummary;
          this.errors.messageAlert = err.response.data.errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.alertData.messageAlert = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    async resendCode() {
      this.errors.messageAlert = "";
      const url = `${this.$config.api}/api/v1/authn/factors/${this.factor.id}/verify/resend`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        stateToken: this.stateToken
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          this.resent = true;
          this.loader = false;
          window.setTimeout(() => {
            this.resent = false;
          }, 5000);
        }
      } catch (err) {
        try {
          this.alertData.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.errors.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
      }
    }
  }
};
</script>