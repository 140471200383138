import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router'
import config from '@/.config.js'
import sessionStorage from './sessionStorage'
import Clipboard from 'v-clipboard'
import '@/assets/css/style.css'
import '@/assets/vendor/bootstrap/css/bootstrap.min.css'


Vue.config.productionTip = false
Vue.prototype.$config = config
Vue.prototype.$session = new sessionStorage()


Vue.use(Clipboard)


new Vue({
  vuetify,
  router, 
  render: h => h(App)
}).$mount('#app')