<style>
.errorMsg {
  color: red;
}
</style>
<template>
  <div>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">Add</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Enroll in multifactor authentication</span>
        </v-card-title>
        <v-container v-if="start" fluid>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  Set up multifactor authentication.
                  Continue multifactor authentication setup to add an additional layer of security when signing in to your account
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="addFactor()">Next</v-btn>
          </v-card-actions>
        </v-container>
        <v-container v-if="second" fluid>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  Setup Authenticator
                  Scan barcode
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row :justify="justify">
                  <img v-bind:src="qrcode" />
                  </v-row>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="4">
                  <a href="#" @click.prevent="showCode =true">Can't scan the code?</a>
                </v-col>
              </v-row>
              <v-row :justify="justify">
                <v-col cols="12">
                  <div class="container-fluid" v-if="showCode">
                    To add the entry manually, provide the following details to the application on your phone.
                    <br/>Account: Angeleno: {{appUserInfo.preferred_username}}
                    <br/>Key:{{activation.sharedSecret}}
                    <br/>Time based: Yes
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="verifyStep()">Next</v-btn>
          </v-card-actions>
        </v-container>
        <v-container v-if="verify" fluid>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">Enter code displayed from the application</v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="code" label="code" required></v-text-field>
                </v-col>
              </v-row>
              <p class="errorMsg" v-if="errors.messageAlert">{{ errors.messageAlert }}</p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="verifyCode(factID,code)">Verify</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      dialog: false,
      loader: false,
      activation: [],
      start: true,
      second: false,
      verify: false,
      qrcode: "",
      showCode: false,
      code: "",
      appUserInfo: this.$root.$children[0].userinfo,
      alertData: {
        typeAlert: "",
        messageAlert: ""
      },
      factID: "",
      errors: {
        messageAlert: ""
      },
      justify: 'center'
    };
  },
  props: {
    factorID: String,
    factorType: String,
    factorTitle: String,
    authmethods: undefined
  },
  methods: {
    async addFactor() {
      this.loader = true;
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      //TODO: need to validate phone number
      const payload = {
        factorType: "token:software:totp",
        provider: "GOOGLE"
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          this.overlayMessage = "TOTP";
          this.saved = true;
          window.setTimeout(() => {
            this.loader = false;
          }, 600);
          if (res.data.status === "PENDING_ACTIVATION") {
            this.start = false;
            this.second = true;
            this.factID = res.data.id;
            this.activation = res.data._embedded.activation;
            this.qrcode = this.activation._links.qrcode.href;
          }

        }
      } catch (err) {
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    verifyStep() {
      this.second = false;
      this.verify = true;
    },
    async verifyCode(factorId, passcode) {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors/${factorId}/lifecycle/activate`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      const payload = {
        passCode: passcode
      };
      try {
        const res = await axios.post(url, payload, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          this.errors.messageAlert = "";
          this.alertData.messageAlert = this.factorTitle + " added";
          this.alertData.typeAlert = "Success";
          this.$emit("ShowAlert", this.alertData);
          this.$emit("AddFactorGroup");
          let index = this.authmethods.find(
            x => x.factorType == this.factorType
          );
          index.status = res.data.status;
          index.id = res.data.id;
          window.setTimeout(() => {
            this.loader = false;
            this.dialog = false;
          }, 600);
        }
      } catch (err) {
        try {
          this.alertData.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.errors.messageAlert =
            err.response.data.errorCauses[0].errorSummary;
          this.alertData.typeAlert = "Warning";
          this.$emit("ShowAlert", this.alertData);
        } catch (e) {
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    }
  }
};
</script>