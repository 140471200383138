<template>
  <v-container id="loadingContainer" fill-height>
    <v-row align-content="center" justify="center">
      <v-col class="subtitle-1 text-center" cols="5" id="loginText">
        {{ msg }}
      </v-col>

      <v-col cols="10">
        <v-progress-linear indeterminate rounded height="10" v-if="render">
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row align-content="center" justify="center">
      <v-col class="subtitle-1 text-center" cols="10" v-if="error">
        <p>
          <router-link to="/help"
            >Click Here to submit a help request.</router-link
          >
          Please include the error code in your request for us to help you
          better.
        </p>
        <p>
          <b>{{ errorCode }}</b>
          <v-btn depressed v-clipboard="() => errorCode">
            Copy Error Code
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: "AngelenoAppLogin",
  data() {
    return {
      msg: "Logging you in...",
      render: true,
      error: false,
      errorCode: null,
    };
  },
  mounted() {
    this.verifyGroups();
  },
  methods: {
    /**
     * Call to lambda function to verify if the user is in the group, and to add them if they are not.
     */
    async verifyGroups() {
      const accessToken = await this.$auth.getAccessToken();

      //call to lambda function.
      const request = await axios
        .post(
          `${process.env.VUE_APP_ANGELENO_LOGIN_API}/oktalogin/api/v1/${this.$route.params.id}`,
          {
            appId: this.$route.params.id,
            //snowPortalRoute: this.$route.params.snowPortalRoute,
            snowPortalRoute: (this.$route.params.snowPortalRoute ? this.$route.params.snowPortalRoute : ""),
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .catch((err) => {
          this.render = false;
          this.msg =
            "An unexpected error has occured. Please try again. If the issue persists, please contact an administrator through our help page and include the error code below:";
          console.log(err);
          this.error = true;
          this.errorCode = err.response.data.logInfo;
        });

      //redirect the user to the app linked on the OKTA side
      if (request.status == 200 || request.status == 204) {
        if (request.data.useTimeout) {
          setTimeout(() => {
            window.location.href = request.data.url;
          }, 8000);
        } else {
          window.location.href = request.data.url;
        }
      }
    },
  },
};
</script>

<style scoped>
#loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

div {
  width: 100%;
}
</style>
