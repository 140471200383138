<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21 21"
    style="enable-background:new 0 0 21 21;"
    xml:space="preserve"
  >
    <g>
      <path
        d="M15.2,9.7c-1.9-0.5-8.1-0.4-9.4,0.1c-0.4,0.1-0.5,0.4-0.5,0.9c0,0,0,1.2,0,1.6c0.1,0.3,0.1,0.6,0.4,0.7
		c4.1,1.6,8.4,0.6,9.8,0.1c0.5-0.2,0.5-0.5,0.5-0.8c0-0.3,0-1.1,0-1.8C16,9.8,15.6,9.8,15.2,9.7z M8.4,12.3c-0.4,0-0.7-0.3-0.7-0.7
		s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7S8.9,12.3,8.4,12.3z M13,12.3c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7
		S13.4,12.3,13,12.3z"
      />
      <path
        d="M10.5,0C4.7,0,0,4.7,0,10.5S4.7,21,10.5,21S21,16.3,21,10.5S16.3,0,10.5,0z M10.5,17.4c-3.7,0-6.7-3-6.7-6.7S6.8,4,10.5,4
		s6.7,3,6.7,6.7S14.2,17.4,10.5,17.4z M19.2,11.9c0,0,0,0.1-0.1,0.2c0,0.5-0.1,1.2-0.3,1.8c-0.3,0.7-0.7,1.4-1.3,1.9
		c-0.6,0.5-1.3,0.9-1.9,1l-0.1,0l0.1-0.1c0,0,0-0.1,0.1-0.1l0-0.1l0.1,0c1.6-0.3,2.6-1.8,3-2.9c0.1-0.4,0.2-0.8,0.2-1.2
		c0,0-0.1,0-0.1,0c0,0.1-0.1,0.5-0.1,0.6c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.6,0-0.7,0s-0.2,0-0.2-0.1c0-0.1,0-4.6,0-4.6
		c0-0.1,0-0.1,0.1-0.1C16.1,2,5.6,1.3,3.1,8.3c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0.1c0,0.1,0,4.6,0,4.6c0,0.1,0,0.1-0.2,0.1s-0.5,0-0.6,0
		c-0.2,0-0.2-0.3-0.2-0.4c0-0.1,0-0.4-0.1-0.4s-0.3,0-0.3-0.2s0-0.4-0.1-0.4s-0.2,0-0.2-0.1c-0.1-0.3-0.1-1.4-0.1-1.8
		c0-0.3,0.2-0.3,0.2-0.3c0.1,0,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0,0.1,0c0-0.1,0.1-0.5,0.1-0.6c0-0.1,0.1-0.2,0.2-0.2
		c0.1,0,0.2,0,0.4,0c1.9-6.8,13-7.2,15.4,0c0.1,0,0.2,0,0.3,0c0.2,0,0.2,0.3,0.2,0.4c0,0.1,0,0.4,0.1,0.4s0.3,0,0.3,0.2
		s0,0.4,0.1,0.4s0.2,0,0.2,0.1c0.1,0.3,0.1,1.4,0.1,1.8C19.4,11.8,19.3,11.9,19.2,11.9z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ChipIcon',
};
</script>