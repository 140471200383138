
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="auth"
      :hide-default-footer="true"
      item-key="factorType"

      class="elevation-1"
    >
      <template v-slot:top>
      </template>
      <template #item.status="{item}">
        <removeFactor
          v-if="item.status == 'ACTIVE'"
          v-bind:authmethods="auth"
          v-bind:factorID="item.id"
          v-bind:factorType="item.factorType"
          v-bind:factorTitle="item.factorTitle"
          @ShowAlert="onAlert"
        />
        <addFactor
          v-else
          v-bind:authmethods="auth"
          v-bind:factorType="item.factorType"
          v-bind:factorTitle="item.factorTitle"
          @ShowAlert="onAlert"
          @AddFactorGroup="onFactorGroup"
        />
      </template>
    </v-data-table>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/.config.js";
import removeFactor from "./RemoveFactor";
import addFactor from "./AddFactor";

export default {
  name: "profile",
  components: {
    removeFactor,
    addFactor
  },

  data() {
    return {
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: "Multi-factor Authentication",
          align: "start",
          sortable: false,
          value: "factorTitle"
        },
        {
          text: "Configured?",
          align: "start",
          sortable: false,
          value: "status"
        }
      ],
      authmethods: [],
      auth:[],
      loader: false
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      await this.getFactorCatalog();
      await this.getFactors();
    },
    async getFactorCatalog() {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors/catalog`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      try {
        const res = await axios.get(url, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          // call sucessful
          let authData = res.data.map(authType => {
            return {
              factorTitle:
                config.authTypesMap[
                  authType.factorType.concat("", authType.provider)
                ],
              factorType: authType.factorType.concat("", authType.provider),
              status: authType.status,
              links: authType._links
            };
          });
          this.authmethods = authData.filter(x => typeof(x.factorTitle) != "undefined");
          window.setTimeout(() => {
            this.loader = false;
          }, 600);
        } else {
          console.log("call failed");
        }
      } catch (err) {
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    async getFactors() {
      const url = `${this.$config.api}/api/v1/users/${this.$root.$children[0].userinfo.sub}/factors`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;
      this.loader = true;
      try {
        const res = await axios.get(url, {
          headers: { Authorization: "Bearer " + accessToken }
        });
        if (res.status == 200) {
          // call sucessful
          for (let authType of res.data) {
            let tempAuthObj =
              config.authTypesMap[
                authType.factorType.concat("", authType.provider)
              ];
            if (typeof tempAuthObj !== "undefined") {
              let authObj = this.$data.authmethods.find(
                authMethod =>
                  authMethod.factorType ==
                  authType.factorType.concat("", authType.provider)
              );

              if (typeof authObj !== "undefined") {
                authObj.id = authType.id;
              }
            }
          }
          //to fix race condition of the ID not being avaible for mfa removal
          this.$data.auth = this.$data.authmethods;
          window.setTimeout(() => {
            this.loader = false;
          }, 600);
        } else {
          console.log("call failed");
        }
      } catch (err) {
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
          console.log(`Something failed here ${err}`);
        }
        this.error = true;
        this.saved = true;
        this.loader = false;
      }
    },
    async onFactorGroup() {
      const url = `${this.$config.api}/api/mfa/v1/group`;
      const accessToken = await this.$auth.getAccessToken();
      this.overlayMessage = undefined;  
      try {
        const res = await axios.put(
          url,
          {},
          {
            headers: { Authorization: "Bearer " + accessToken }
          }
        );
        if (res.status == 204) {
          console.log("success");
        } else {
          console.log("call failed");
        }
      } catch (err) {
        try {
          this.overlayMessage = err.response.data.errorCauses[0].errorSummary;
        } catch (e) {
          //lazily handle unexpected responses
          this.overlayMessage = "invalid request";
          console.log(`Something failed here ${err}`);
        }
        this.error = true;
        this.saved = true;
      }
    },
    onAlert: function(obj) {
      this.$emit("ShowAlert", obj);
    }
  }
};
</script>