      
<template>
  <div class="help-container">
    
    <v-container fluid>
      <h3 class="header-text" style="">Help</h3>
    <h4 class="" style="padding-bottom: 10px;">Frequently Asked Questions</h4>
      <v-row></v-row>
      <v-tabs :vertical="verticaltabs" show-arrows v-model="activeTab">
        <v-tab key="accounthelp">Account</v-tab>
        <v-tab key="passwordhelp">Password</v-tab>
        <v-tab key="advsecurityhelp">Advanced Security</v-tab>
        <v-tab-item key="accounthelp">
          <AccountHelp></AccountHelp>
        </v-tab-item>
        <v-tab-item key="passwordhelp">
          <PasswordHelp></PasswordHelp>
        </v-tab-item>
        <v-tab-item key="advsecurityhelp">
          <AdvSecurityHelp></AdvSecurityHelp>
        </v-tab-item>
      </v-tabs>
    </v-container>
    <v-container>
      <h4 class="" style="padding-bottom: 10px;">Contact Us</h4> <!-- This is the one that matters-->
      <iframe :src="this.$config.contactPageAngeleno" 
      title="Angeleno Contacts Us"
      width="100%" scrolling="no" height="715px" style="border:none;" ></iframe>

    </v-container>
  </div>
</template>
<script>
import AccountHelp from "@/views/AccountHelp";
import PasswordHelp from "@/views/PasswordHelp";
import AdvSecurityHelp from "@/views/AdvSecurityHelp";
export default {
  name: "help",
  components: {
    AccountHelp,
    PasswordHelp,
    AdvSecurityHelp
  },
  data() {
    return {
      verticaltabs: true,
       activeTab: this.focusTab
    };
  },
  methods: {
  onResize() {
    if (window.innerWidth > 1024) {
      this.verticaltabs = true
    } else {
      this.verticaltabs = false
    }
  }
},
created() {
  this.onResize();
  window.addEventListener('resize', this.onResize)
},

beforeDestroy() {
  window.removeEventListener('resize', this.onResize)
},
};
</script>
 <style>
 .help-container{
     width:100% !important;
 }
.faq-container {
  padding: 10px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.v-tab {
  justify-content: start;
}

</style>