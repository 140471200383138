<template>
 <div class="img-container" style="min-height: 275px;width: 100%;">
      <img :src="hero" class="auth-org-logo" />
      <div class="top-left">
          <h3 class="hero-text">
          Your one account to access City of Los Angeles services.
          </h3>
        <p class="hero-sub-text">Use one account and password to access participating City services.  
      
        </p>
        <p>
          
          <button type="button" class="btn btn-success" id="green-button">
                  <a
                   :href = "this.$config.registerTodayLink"
                    target="_blank"
                  >Register Today!</a>
                </button>
        </p>
      
      </div>
    </div>
</template>
<script>
export default {
  name: "Hero",
  data() {
    return {
      hero: require("@/assets/img/Angeleno_hero.jpg"),

    };
  },
  created() {
  }
};
</script>
<style >



/*

.main-container {
  max-width: 100%;
  padding-top: 0;
  margin-right: 10%;
}



/* This controls the Hero img saturation *
.img-container .auth-org-logo { 
filter: brightness(0.8) saturate(1.5);
}

.img-container .auth-org-logo { 
filter: brightness(0.8) saturate(1.5);
}
.v-toolbar__content, .v-toolbar__extension {
background-color: #019630;
}




.img-container .auth-org-logo { 
filter: brightness(0.8) saturate(1.5);
}
.v-toolbar__content, .v-toolbar__extension {
background-color: #019630;
}
@media screen and (min-width: 815px) {
.hero-sub-text {
font-size: 1.2rem;
}
}

.hero_text{
  margin-right: 70%;
}

.hero-sub-text {font-size: 1rem;}

*/

</style>