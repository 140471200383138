import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import  ChipIcon from '@/components/Chip';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    chipicon: ChipIcon
  },
});
