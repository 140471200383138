<template>
  <div>
    <main id="main">
      <!--
      <section id="blurb" class="blurb">
           </section>
-->
        <div class="container blurb" id="resources">
          <h3 class="header-text">What is an Angeleno Account and why should I have one?</h3>
          <div class="row">
            <div class="col-lg-4">
              <img :src="placeholder" class="img-fluid" alt="People at counter" />
            </div>
            <div class="col-lg-8 pt-4 pt-lg-0">
              <div class="py-2">
                <p>
                 With the Angeleno Account, the City is providing a single, private and secure account to access City services.
                </p>
                <p>
                 The Angeleno Account is used to securely
                 <ul>
                   <li>Make appointments with  <a
                   :href = "this.ani"
                    target="_blank"
                  >Animal Services</a></li>
                   <li>Access contracting opportunities on  <a
                   :href = "this.ramp"
                    target="_blank" 
                  >rampla.org</a></li>
                   <li>Make appointments with the  <a
                   :href = "this.lahd"
                    target="_blank"
                  >Los Angeles Housing Department's public counter</a></li>
                   <li>And  <a
                   :href = "this.apps"
                    target="_blank"
                  >more </a></li>
                 </ul>
                </p>
                <br />
                <p>
                  The Angeleno Account is easy to use. You no longer need to juggle usernames and passwords for different City services. Your Angeleno Account is your one account to unlock many doors at City Hall.

                </p>
                <br/>
                <button type="button" class="btn btn-success" id="green-button">
                  <a
                   :href = "this.$config.registerTodayLink"
                    target="_blank"
                  >Register Today!</a>
                </button>
              </div>
            </div>
          </div>
        </div>
   
    </main>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    
    return {
      ani: this.$config.homeAnimalServiceLink,
      ramp:this.$config.homeRAMPLink ,
      lahd: this.$config.homeLAHDLink,
      apps: "/apps",
      placeholder: require("@/assets/img/angeleno_placeholder.jpg"),
    };
  },
  async created() {
  },
};
</script>

<!--
<style  scoped>

.main-container {
  top: 10%;
  max-width: 100%;
  padding-top: 0;
}

@media (max-width: 991px) {
  #col-lg-4 {
    display: none;
  }
  .img-fluid{
display: none;
  }
}


.blurb .py-2 {
  margin-top: -10%;
    font-size: 1.1rem;
    color: #848484;
}
</style>

-->