<script>
export default {
  name: "AngelenoLogout",
  data() {
    return {};
  },
  mounted() {
    this.logout();
  },
  methods: {
    async logout() {
      this.origin = window.location.origin;
      this.token = await this.$auth.getIdToken();
      await this.$auth.logout();
      window.location.href =
        process.env.VUE_APP_ISSUER +
        "/v1/logout?id_token_hint=" +
        this.token +
        "&post_logout_redirect_uri=" +
        this.origin;
    },
  },
};
</script>
