<template>
  <v-autocomplete
    :items="items"
    v-model="countryCode"
    name="Country Code"
    label="Country Code"
    item-text="name"
    item-value="name"
    @change="changedValue"
    return-object
  ></v-autocomplete>
</template>
<script>
export default {
    props:{
    onSelected: Function,
    },
  data() {
    return {
      countryCode: {
        value: "+1",
        name: "United States"
      },
      items: [
        {
          name: "Afghanistan",
          value: "+93"
        },
        {
          name: "Albania",
          value: "+355"
        },
        {
          name: "Algeria",
          value: "+213"
        },
        {
          name: "American Samoa",
          value: "+1"
        },
        {
          name: "Andorra",
          value: "+376"
        },
        {
          name: "Angola",
          value: "+244"
        },
        {
          name: "Anguilla",
          value: "+1"
        },
        {
          name: "Antarctica",
          value: "+672"
        },
        {
          name: "Antigua and Barbuda",
          value: "+1"
        },
        {
          name: "Argentina",
          value: "+54"
        },
        {
          name: "Armenia",
          value: "+374"
        },
        {
          name: "Aruba",
          value: "+297"
        },
        {
          name: "Australia",
          value: "+61"
        },
        {
          name: "Austria",
          value: "+43"
        },
        {
          name: "Azerbaijan",
          value: "+994"
        },
        {
          name: "Bahamas",
          value: "+1"
        },
        {
          name: "Bahrain",
          value: "+973"
        },
        {
          name: "Bangladesh",
          value: "+880"
        },
        {
          name: "Barbados",
          value: "+1"
        },
        {
          name: "Belarus",
          value: "+375"
        },
        {
          name: "Belgium",
          value: "+32"
        },
        {
          name: "Belize",
          value: "+501"
        },
        {
          name: "Benin",
          value: "+229"
        },
        {
          name: "Bermuda",
          value: "+1"
        },
        {
          name: "Bhutan",
          value: "+975"
        },
        {
          name: "Bolivia, Plurinational State of",
          value: "+591"
        },
        {
          name: "Bosnia and Herzegovina",
          value: "+387"
        },
        {
          name: "Botswana",
          value: "+267"
        },
        {
          name: "Brazil",
          value: "+55"
        },
        {
          name: "British Indian Ocean Territory",
          value: "+246"
        },
        {
          name: "Brunei Darussalam",
          value: "+673"
        },
        {
          name: "Bulgaria",
          value: "+359"
        },
        {
          name: "Burkina Faso",
          value: "+226"
        },
        {
          name: "Burundi",
          value: "+257"
        },
        {
          name: "Cambodia",
          value: "+855"
        },
        {
          name: "Cameroon",
          value: "+237"
        },
        {
          name: "Canada",
          value: "+1"
        },
        {
          name: "Cape Verde",
          value: "+238"
        },
        {
          name: "Cayman Islands",
          value: "+1"
        },
        {
          name: "Central African Republic",
          value: "+236"
        },
        {
          name: "Chad",
          value: "+235"
        },
        {
          name: "Chile",
          value: "+56"
        },
        {
          name: "China",
          value: "+86"
        },
        {
          name: "Christmas Island",
          value: "+61"
        },
        {
          name: "Colombia",
          value: "+57"
        },
        {
          name: "Comoros",
          value: "+269"
        },
        {
          name: "Congo",
          value: "+242"
        },
        {
          name: "Congo, the Democratic Republic of the",
          value: "+243"
        },
        {
          name: "Cook Islands",
          value: "+682"
        },
        {
          name: "Costa Rica",
          value: "+506"
        },
        {
          name: "Croatia",
          value: "+385"
        },
        {
          name: "Cuba",
          value: "+53"
        },
        {
          name: "Cyprus",
          value: "+357"
        },
        {
          name: "Czech Republic",
          value: "+420"
        },
        {
          name: "Côte d'Ivoire",
          value: "+225"
        },
        {
          name: "Denmark",
          value: "+45"
        },
        {
          name: "Djibouti",
          value: "+253"
        },
        {
          name: "Dominica",
          value: "+1"
        },
        {
          name: "Dominican Republic",
          value: "+1"
        },
        {
          name: "Ecuador",
          value: "+593"
        },
        {
          name: "Egypt",
          value: "+20"
        },
        {
          name: "El Salvador",
          value: "+503"
        },
        {
          name: "Equatorial Guinea",
          value: "+240"
        },
        {
          name: "Eritrea",
          value: "+291"
        },
        {
          name: "Estonia",
          value: "+372"
        },
        {
          name: "Ethiopia",
          value: "+251"
        },
        {
          name: "Falkland Islands (Malvinas)",
          value: "+5000"
        },
        {
          name: "Faroe Islands",
          value: "+298"
        },
        {
          name: "Fiji",
          value: "+679"
        },
        {
          name: "Finland",
          value: "+358"
        },
        {
          name: "France",
          value: "+33"
        },
        {
          name: "French Guiana",
          value: "+594"
        },
        {
          name: "French Polynesia",
          value: "+689"
        },
        {
          name: "Gabon",
          value: "+241"
        },
        {
          name: "Gambia",
          value: "+220"
        },
        {
          name: "Georgia",
          value: "+995"
        },
        {
          name: "Germany",
          value: "+49"
        },
        {
          name: "Ghana",
          value: "+233"
        },
        {
          name: "Gibraltar",
          value: "+350"
        },
        {
          name: "Greece",
          value: "+30"
        },
        {
          name: "Greenland",
          value: "+299"
        },
        {
          name: "Grenada",
          value: "+1"
        },
        {
          name: "Guadeloupe",
          value: "+590"
        },
        {
          name: "Guam",
          value: "+1"
        },
        {
          name: "Guatemala",
          value: "+502"
        },
        {
          name: "Guernsey",
          value: "+44"
        },
        {
          name: "Guinea",
          value: "+224"
        },
        {
          name: "Guinea-Bissau",
          value: "+245"
        },
        {
          name: "Guyana",
          value: "+592"
        },
        {
          name: "Haiti",
          value: "+509"
        },
        {
          name: "Holy See (Vatican City State)",
          value: "+39"
        },
        {
          name: "Honduras",
          value: "+504"
        },
        {
          name: "Hong Kong",
          value: "+852"
        },
        {
          name: "Hungary",
          value: "+36"
        },
        {
          name: "Iceland",
          value: "+354"
        },
        {
          name: "India",
          value: "+91"
        },
        {
          name: "Indonesia",
          value: "+62"
        },
        {
          name: "Iran, Islamic Republic of",
          value: "+98"
        },
        {
          name: "Iraq",
          value: "+964"
        },
        {
          name: "Ireland",
          value: "+353"
        },
        {
          name: "Israel",
          value: "+972"
        },
        {
          name: "Italy",
          value: "+39"
        },
        {
          name: "Jamaica",
          value: "+1"
        },
        {
          name: "Japan",
          value: "+81"
        },
        {
          name: "Jersey",
          value: "+44"
        },
        {
          name: "Jordan",
          value: "+962"
        },
        {
          name: "Kazakhstan",
          value: "+7"
        },
        {
          name: "Kenya",
          value: "+254"
        },
        {
          name: "Kiribati",
          value: "+686"
        },
        {
          name: "Korea, Democratic People's Republic of",
          value: "+850"
        },
        {
          name: "Korea, Republic of",
          value: "+82"
        },
        {
          name: "Kosovo, Republic of",
          value: "+383"
        },
        {
          name: "Kuwait",
          value: "+965"
        },
        {
          name: "Kyrgyzstan",
          value: "+996"
        },
        {
          name: "Lao People's Democratic Republic",
          value: "+856"
        },
        {
          name: "Latvia",
          value: "+371"
        },
        {
          name: "Lebanon",
          value: "+961"
        },
        {
          name: "Lesotho",
          value: "+266"
        },
        {
          name: "Liberia",
          value: "+231"
        },
        {
          name: "Libya",
          value: "+218"
        },
        {
          name: "Liechtenstein",
          value: "+423"
        },
        {
          name: "Lithuania",
          value: "+370"
        },
        {
          name: "Luxembourg",
          value: "+352"
        },
        {
          name: "Macao",
          value: "+853"
        },
        {
          name: "Macedonia, the former Yugoslav Republic of",
          value: "+389"
        },
        {
          name: "Madagascar",
          value: "+261"
        },
        {
          name: "Malawi",
          value: "+265"
        },
        {
          name: "Malaysia",
          value: "+60"
        },
        {
          name: "Maldives",
          value: "+960"
        },
        {
          name: "Mali",
          value: "+223"
        },
        {
          name: "Malta",
          value: "+356"
        },
        {
          name: "Marshall Islands",
          value: "+692"
        },
        {
          name: "Martinique",
          value: "+596"
        },
        {
          name: "Mauritania",
          value: "+222"
        },
        {
          name: "Mauritius",
          value: "+230"
        },
        {
          name: "Mayotte",
          value: "+262"
        },
        {
          name: "Mexico",
          value: "+52"
        },
        {
          name: "Micronesia, Federated States of",
          value: "+691"
        },
        {
          name: "Moldova, Republic of",
          value: "+373"
        },
        {
          name: "Monaco",
          value: "+377"
        },
        {
          name: "Mongolia",
          value: "+976"
        },
        {
          name: "Montenegro",
          value: "+382"
        },
        {
          name: "Montserrat",
          value: "+1"
        },
        {
          name: "Morocco",
          value: "+212"
        },
        {
          name: "Mozambique",
          value: "+258"
        },
        {
          name: "Myanmar",
          value: "+95"
        },
        {
          name: "Namibia",
          value: "+264"
        },
        {
          name: "Nauru",
          value: "+674"
        },
        {
          name: "Nepal",
          value: "+977"
        },
        {
          name: "Netherlands",
          value: "+31"
        },
        {
          name: "Netherlands Antilles",
          value: "+599"
        },
        {
          name: "New Caledonia",
          value: "+687"
        },
        {
          name: "New Zealand",
          value: "+64"
        },
        {
          name: "Nicaragua",
          value: "+505"
        },
        {
          name: "Niger",
          value: "+227"
        },
        {
          name: "Nigeria",
          value: "+234"
        },
        {
          name: "Niue",
          value: "+683"
        },
        {
          name: "Norfolk Island",
          value: "+672"
        },
        {
          name: "Northern Mariana Islands",
          value: "+1"
        },
        {
          name: "Norway",
          value: "+47"
        },
        {
          name: "Oman",
          value: "+968"
        },
        {
          name: "Pakistan",
          value: "+92"
        },
        {
          name: "Palau",
          value: "+680"
        },
        {
          name: "Palestine, State of",
          value: "+970"
        },
        {
          name: "Panama",
          value: "+507"
        },
        {
          name: "Papua New Guinea",
          value: "+675"
        },
        {
          name: "Paraguay",
          value: "+595"
        },
        {
          name: "Peru",
          value: "+51"
        },
        {
          name: "Philippines",
          value: "+63"
        },
        {
          name: "Pitcairn",
          value: "+64"
        },
        {
          name: "Poland",
          value: "+48"
        },
        {
          name: "Portugal",
          value: "+351"
        },
        {
          name: "Puerto Rico",
          value: "+1"
        },
        {
          name: "Qatar",
          value: "+974"
        },
        {
          name: "Romania",
          value: "+40"
        },
        {
          name: "Russian Federation<",
          value: "+7"
        },
        {
          name: "Rwanda",
          value: "+250"
        },
        {
          name: "Réunion",
          value: "+262"
        },
        {
          name: "Saint Helena, Ascension and Tristan da Cunha",
          value: "+290"
        },
        {
          name: "Saint Kitts and Nevis",
          value: "+1"
        },
        {
          name: "Saint Lucia",
          value: "+1"
        },
        {
          name: "Saint Pierre and Miquelon",
          value: "+508"
        },
        {
          name: "Saint Vincent and the Grenadines",
          value: "+1"
        },
        {
          name: "Samoa",
          value: "+685"
        },
        {
          name: "San Marino",
          value: "+378"
        },
        {
          name: "Saudi Arabia",
          value: "+966"
        },
        {
          name: "Senegal",
          value: "+221"
        },
        {
          name: "Serbia",
          value: "+381"
        },
        {
          name: "Seychelles",
          value: "+248"
        },
        {
          name: "Sierra Leone",
          value: "+232"
        },
        {
          name: "Singapore",
          value: "+65"
        },
        {
          name: "Slovakia",
          value: "+421"
        },
        {
          name: "Slovenia",
          value: "+386"
        },
        {
          name: "Solomon Islands",
          value: "+677"
        },
        {
          name: "Somalia",
          value: "+252"
        },
        {
          name: "South Africa",
          value: "+27"
        },
        {
          name: "South Georgia and the South Sandwich Islands",
          value: "+500"
        },
        {
          name: "South Sudan",
          value: "+211"
        },
        {
          name: "Spain",
          value: "+34"
        },
        {
          name: "Sri Lanka",
          value: "+94"
        },
        {
          name: "Sudan",
          value: "+249"
        },
        {
          name: "Suriname",
          value: "+597"
        },
        {
          name: "Svalbard and Jan Mayen",
          value: "+47"
        },
        {
          name: "Swaziland",
          value: "+268"
        },
        {
          name: "Sweden",
          value: "+46"
        },
        {
          name: "Switzerland",
          value: "+41"
        },
        {
          name: "Syrian Arab Republic",
          value: "+963"
        },
        {
          name: "São Tomé and Príncipe",
          value: "+239"
        },
        {
          name: "Taiwan",
          value: "+886"
        },
        {
          name: "Tajikistan",
          value: "+992"
        },
        {
          name: "Tanzania, United Republic of",
          value: "+255"
        },
        {
          name: "Thailand",
          value: "+66"
        },
        {
          name: "Timor-Leste",
          value: "+670"
        },
        {
          name: "Togo",
          value: "+228"
        },
        {
          name: "Tokelau",
          value: "+690"
        },
        {
          name: "Tonga",
          value: "+676"
        },
        {
          name: "Trinidad and Tobago",
          value: "+1"
        },
        {
          name: "Tunisia",
          value: "+216"
        },
        {
          name: "Turkey",
          value: "+90"
        },
        {
          name: "Turkmenistan",
          value: "+993"
        },
        {
          name: "Turks and Caicos Islands",
          value: "+1"
        },
        {
          name: "Tuvalu",
          value: "+688"
        },
        {
          name: "Uganda",
          value: "+256"
        },
        {
          name: "Ukraine",
          value: "+380"
        },
        {
          name: "United Arab Emirates",
          value: "+971"
        },
        {
          name: "United Kingdom",
          value: "+44"
        },
        {
          name: "United States",
          value: "+1"
        },
        {
          name: "United States Minor Outlying Islands",
          value: "+1"
        },
        {
          name: "Uruguay",
          value: "+598"
        },
        {
          name: "Uzbekistan",
          value: "+998"
        },
        {
          name: "Vanuatu",
          value: "+678"
        },
        {
          name: "Venezuela, Bolivarian Republic of",
          value: "+58"
        },
        {
          name: "Viet Nam",
          value: "+84"
        },
        {
          name: "Virgin Islands, British",
          value: "+1"
        },
        {
          name: "Virgin Islands, U.S.",
          value: "+1"
        },
        {
          name: "Wallis and Futuna",
          value: "+681"
        },
        {
          name: "Western Sahara",
          value: "+212"
        },
        {
          name: "Yemen",
          value: "+967"
        },
        {
          name: "Zambia",
          value: "+260"
        },
        {
          name: "Zimbabwe",
          value: "+263"
        },
        {
          name: "Åland Islands",
          value: "+358"
        }
      ]
    };
  },
  methods: {
    changedValue: function(value) {
      this.onSelected(value);
      //receive the value selected (return an array if is multiple)
    }
  }
};
</script>